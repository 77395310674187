import Metadata from '../components/Metadata';
import Navigation from '../components/Navigation';
import React from 'react';

export default function About() {
  return (
    <>
      <Metadata title="About" />
      <div>
        About
        <Navigation />
      </div>
    </>
  );
}
